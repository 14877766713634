import defaultTheme from 'tailwindcss/defaultTheme'

/** @type {import('tailwindcss').Config} */
export default {
    content: [
        './src/**/*.vue'
    ],
    safelist: [
        {pattern: /grid-cols-(6|7|8|)/},
        {pattern: /(bg|text)-(purple|light-blue|light-green|)-(base|dark)/},
    ],
    theme: {
        extend: {
            backdropBlur: {
                xl: '30px'
            },
            boxShadow: {
                gdpr: '0px 0px 40px #16142680',
                glow: '0px 0px 20px #6464ED76',
                organization: '0px 0px 40px #3535456E'
            },
            colors: {
                'deep-indigo': {
                    base: '#1f1c37',
                    black: '#08051C',
                    card: '#1F1C38BF',
                    clear: '#08051CBF',
                    dark: '#1F1C39',
                    grey: '#FFFFFF12',
                    heavy: '#302D48',
                    icon: '#827EAA',
                    light: '#FFFFFF0D',
                    opacity: '#3A384A',
                    pale: '#8A8BA0',
                    soft: '#151228',
                    strong: '#3C365C',
                    stronger: '#37335D',
                    vitrine: '#353545'
                },
                error: {
                    base: '#CE0000',
                    pale: '#FFEAEA'
                },
                'light-blue': {
                    base: '#84B9F8',
                    dark: '#84B9F826'
                },
                'light-green': {
                    base: '#91DCED',
                    dark: '#91DCED26'
                },
                lilac: {
                    base: '#6464ed',
                    dark: '#B6ACFF26',
                    grey: '#C9CBDFCC',
                    medium: '#B6ACFF40',
                    nav: '#C3BCFA',
                    light: '#B6ACFF4D',
                    pale: '#B6ACFF',
                    section: '#DFDBFF',
                    strong: '#C9CBDF12',
                    vitrine: '#B6ACFF73'
                },
                modal: '#FFFFFF1A',
                purple: {
                    base: '#B6ACFF',
                    dark: '#6464ED26'
                }
            },
            fontFamily: {
                'sans': ['neue-haas-grotesk-display', ...defaultTheme.fontFamily.sans]
            },
            fontSize: {
                'sm': ['0.9375rem', {
                    letterSpacing: '0.27px',
                    lineHeight: '1.0625rem'
                }],
                'base': ['1rem', {
                    letterSpacing: '0.3px',
                    lineHeight: '1.5rem'
                }],
                'lg': ['1.125rem', {
                    letterSpacing: '0.3px',
                    lineHeight: '1.75rem'
                }],
                'xl': ['1.25rem', {
                    letterSpacing: '0.3px',
                    lineHeight: '1.75rem'
                }],
                '2xl': ['1.5625rem', {
                    letterSpacing: '0.38px',
                    lineHeight: '2.8125rem'
                }],
                '3xl': ['1.875rem', {
                    letterSpacing: '0px',
                    lineHeight: '2.5rem'
                }],
                '4xl': ['2.0625rem', {
                    letterSpacing: '0.49px',
                    lineHeight: '3rem'
                }],
                '5xl': ['2.8125rem', {
                    letterSpacing: '0.68px',
                    lineHeight: '3.625rem'
                }],
                '7xl': ['4.375rem', {
                    letterSpacing: '0',
                    lineHeight: '4.5rem'
                }],
                '8xl': ['5.3125rem', {
                    letterSpacing: '0',
                    lineHeight: '5.5rem'
                }]
            },
            spacing: {
                '0.75': '0.1875rem',
                '4.5': '1.125rem',
                '5.5': '1.375rem',
                '15': '3.75rem',
                '16.5': '4.125rem',
                '17.5': '4.375rem',
                '22.5': '5.625rem',
                '25': '6.25rem',
                '30': '7.5rem',
                '32.5': '8.125rem',
                '50': '12.5rem',
                '75': '18.75rem',
                '100': '25rem',
                '112.5': '28.125rem'
            },
            transitionTimingFunction: {
                'cubic-bezier': 'cubic-bezier(0.6, 0.05, 0.01, 0.99)'
            }
        }
    },
    plugins: [],
}

