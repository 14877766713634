<template>
  <nav class="fixed z-50 bg-deep-indigo-black md:bg-transparent md:relative top-0 w-full flex justify-between items-center gap-10 h-pad py-6 md:py-0 md:h-[17dvh] transition-[background]">
    <LangRouterLink :to="{name: $routes.ORGANIZATION.INDEX}" class="transition-[transform] hover:scale-[1.02]">
      <img src="@/assets/images/logo.svg" alt="" class="h-8"/>
    </LangRouterLink>
    <div class="flex justify-end gap-5 md:gap-10 items-center">
      <UnderlineButton :to="{name: $routes.ORGANIZATION.EDIT }"
                       :label="$auth.logged_in ? $t('organization.edit') : $t('organization.create')" icon="website" @click="$onboarding.handleOutro()"/>
      <Transition name="fade">
        <button v-if="$auth.logged_in" class="hidden md:block button button-secondary" @click.stop="$auth.logout()" type="button">
          {{ $t('logout') }}
        </button>
      </Transition>
      <LangSwitch/>
    </div>
  </nav>
</template>

<script>
import {LangRouterLink} from '@/components/helpers'
import LangSwitch from '@/components/navigation/LangSwitch.vue'
import {UnderlineButton} from '@/components/small'

export default {
  name: 'Navigation',
  components: {LangRouterLink, LangSwitch, UnderlineButton}
}
</script>