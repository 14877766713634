export default {
    competences: 'Competences',
    contact: 'Contact',
    create: 'Register my organization',
    edit: 'Edit my organization',
    follow: 'Follow',
    help: {
        how_to: 'How to talk to Miria?',
        ideas: 'Ideas to start your search'
    },
    index: {
        placeholder: 'What do you need?',
        title: 'Propel <em>your AI project</em> to success with Miria experts'
    },
    name: 'Organization Name',
    partnership_benefits: 'What do you gain from a partnership with this organization?',
    search: 'Search an organization',
    sections: {
        details: 'Details',
        intro: 'Intro',
        links: 'Links',
        offer: 'Offer',
        visit: 'Visit'
    },
    section_slugs: {
        details: 'details',
        intro: 'intro',
        links: 'links',
        offer: 'offer'
    },
    target_audiences: 'Target audiences',
    type: 'Type',
    view_more: 'View more organizations',
    visit: 'Visit your profile'
}