export default {
    login: {
        description: 'Propulsez votre projet d\'IA vers le succès avec les experts Miria.',
        title: 'Se connecter - Miria'
    },
    organization: {
        index: {
            description: 'Propulsez votre projet d\'IA vers le succès avec les experts Miria.',
            title: 'Miria'
        },
        edit: {
            description: 'Propulsez votre projet d\'IA vers le succès avec les experts Miria.',
            title: 'Mon organisation - Miria'
        },
        show: {
            description: 'Propulsez votre projet d\'IA vers le succès avec les experts Miria.',
            title: '[ORGANIZATION_NAME] - Miria'
        },
    },
    privacy: {
        description: 'Propulsez votre projet d\'IA vers le succès avec les experts Miria.',
        title: 'Politique de protection de la vie privée - Miria'
    },
    vitrine: {
        description: 'Propulsez votre projet d\'IA vers le succès avec les experts Miria.',
        title: 'Tableau de bord Migration Vitrine IA - Miria'
    }
}