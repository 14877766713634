export default {
    banner: {
        accept: 'Accept',
        description: 'We use cookies and other tracking technologies to enhance your browsing experience on our website, to analyze our website traffic, and to understand where our visitors come from.',
        more: 'More information.',
        settings: 'Manage preferences'
    },
    settings: {
        analytics: {
            description: 'Google Analytics cookies help us improve our website by collecting and reporting information on how you use it. The cookies collect information completely anonymously. For more information on how these cookies work, please see our Privacy Policy page.',
            title: 'Analytics Cookies'
        },
        description: 'When you visit this site, it may store or retrieve information on your browser, mostly in the form of cookies. This information does not directly identify you, but it is used to make browsing easier and offer a more personalized experience. Because we respect your right to privacy, you can choose not to allow certain types of cookies by adjusting the default settings.',
        more: 'For more information on the cookies used, see our',
        save: 'Save and close',
        title: 'Our Use of Cookies'
    }
}