export default {
    account: {
        content: '<p>You can create an account on the Website to start searching for potential funding programs for your project. To ensure the security of your account, your password should include numbers, letters, and special characters. You are responsible for protecting your password and all activities conducted on your account, whether or not you have authorized such activities. If you become aware of or reasonably suspect any unauthorized access to your account, including theft, loss, or unauthorized disclosure or use of your password, please change your password immediately and notify us by contacting us as described herein.</p>',
        title: 'Account Creation'
    },
    changes: {
        content: '<p>We reserve the unilateral right to make changes to this Policy at any time. Such changes will be posted on the Website and will take effect immediately after posting.</p>' +
            '<p>By continuing to use the Website after a Policy update is posted, you consent to our use of your personal information in accordance with the amended policy. If a User fails or refuses to accept the updated version of this policy, their access to the Website may be suspended from the effective date of the update.</p>',
        title: 'Policy Changes'
    },
    ciq: {
        content: '<p>The CIQ has appointed a privacy officer responsible for ensuring compliance with this Policy and applicable laws regarding the protection of your personal information. If you have any questions, concerns, or complaints regarding the application of this Policy or the management of your personal information, please contact our privacy officer whose contact details are provided in the "Contact Us" section below.</p>',
        title: 'CIQ Privacy Officer'
    },
    collect: {
        content: '<p>We collect personal information with your consent or as permitted or required by applicable laws. Your consent may be express or implied, depending on the circumstances and applicable law.</p>' +
            '<p>CIQ services are not intended for minors. Therefore, we do not knowingly collect or process personal information about minors. By using the Website, you represent and warrant that you have reached the age of majority in your place of residence or that you are authorized to use the Website by a person with parental authority over you. If we become aware that we have collected personal information from minors without such authorization, we will take appropriate steps to delete such personal information from our servers and those of our partners.</p>' +
            '<p>CIQ will limit the collection of personal information, both in terms of nature and volume, to what is necessary for the purposes for which it is collected. We will also ensure that the personal information we hold is accurate, complete, and up to date.</p>',
        title: 'Collection of Personal Information'
    },
    contact: {
        content: '<p>If you have any questions about this Policy or wish to make a written request to our privacy officer, please contact us as follows:</p>',
        title: 'Contact Us'
    },
    external_links: {
        content: 'In addition to what is mentioned elsewhere in this Policy, the Website may include hyperlinks to websites associated with the Site, such as lebarometre.ca, or to external sites operated by third parties. These include websites of our funding partners as well as social media platforms like Twitter, Facebook, LinkedIn, and YouTube. By clicking on these hyperlinks, you will leave the Website to visit third-party sites, which may have different privacy policies and practices. This Policy no longer applies when you leave our Website. Please review the privacy policies of these other sites before providing any personal information. We are not responsible for the collection, use, and disclosure practices of other entities that you may access from our Website, and we accept no responsibility in this regard.',
        title: 'External Links and Forms'
    },
    information_collected: {
        content: '<h3>What Information Do We Collect?</h3>' +
            '<p>In addition to the above, we may automatically collect information about you when you use the Website, primarily the following:</p>' +
            '<ul>' +
            '<li>IP address;</li>' +
            '<li>Unique device identifiers;</li>' +
            '<li>Operating system;</li>' +
            '<li>Mobile network information;</li>' +
            '<li>Browser type;</li>' +
            '<li>Existing applications;</li>' +
            '<li>Browsing activity;</li>' +
            '<li>Type of device used;</li>' +
            '<li>Pages visited and queries made;</li>' +
            '<li>Time and day of connection;</li>' +
            '<li>Links clicked;</li>' +
            '<li>Geolocation; and</li>' +
            '<li>Browser language.</li>' +
            '</ul>' +
            '<h3>How and Why Are These Information Collected?</h3>' +
            '<p>This information is collected primarily through cookies. These are small files stored on your computer or any electronic communication device you use when visiting the Website. These files allow the exchange of information between the Website and your browser.</p>' +
            '<p>We analyze this data to help determine how Users access certain parts of the Website and who our Users are. By recognizing usage patterns and trends, we are able to design the Website more effectively to improve Users’ experience, both in terms of content and usability.</p>' +
            '<p>We use the following cookies:</p>' +
            '<ul>' +
            '<li>Session cookies: These cookies are essential for the proper functioning of the Website and its features. They do not collect information intended for marketing or advertising purposes. Session cookies are encrypted, unrecognizable identifiers necessary for you not to have to log in on every page you visit, among other things.</li>' +
            '<li>Google Analytics cookies: Google Analytics collects several types of information related to your browsing on the Website, such as the pages you visit, the date and time of your visit, and the links you click. Google Analytics also collects, among other things, the type of operating system on your computer, the language of your browser, the name of your Internet service provider, and your geographic location. Google uses this information solely to generate statistics and reports on browsing on the Website. The traffic and usage data collected by Google (obtained through Google’s cookies on the Website) is subject to Google’s privacy policy.</li>' +
            '<li>Third-party cookies: We may allow third-party cookies to track your journey across different websites and provide you with targeted ads that we believe may interest you. However, you can choose to opt out of receiving them at any time.</li>' +
            '<li>We only collect and use the information required and necessary to achieve the purposes mentioned above. Most browsers automatically accept cookies, but you can usually refuse them or accept them selectively by adjusting your preferences in your browser. If you disable cookies, some portions of the Website may not be available to you. However, you should still be able to browse and make purchases.</li>' +
            '</ul>' +
            '<p>Except as described herein, CIQ does not track your activity over time and across different websites or online services, except among related websites that constitute CIQ Websites and as described herein. We do not allow our third-party service providers to collect information on the Website for other websites or their own needs other than those described herein.</p>' +
            '<p>If you use a public computer or share a computer with others, we recommend that you clear your browsing history before ending each session.</p>',
        title: 'Information We Automatically Collect'
    },
    information_provided: {
        content: '<h3>What Information Is Collected?</h3>' +
            '<p>We collect the following personal information, which you voluntarily provide to us:</p>' +
            '<ul>' +
            '<li>Contact information, such as your first and last name, mailing address, email address, and phone number;</li>' +
            '<li>Professional or employment-related information, such as your resume and other information you provide when applying for a job at CIQ;</li>' +
            '<li>Other information that may constitute personal information, such as details about your innovation project or your industry.</li>' +
            '</ul>' +
            '<h3>How Is This Information Collected?</h3>' +
            '<p>The personal information we collect about you is gathered during interactions with the Website or with us in the following ways:</p>' +
            '<ul>' +
            '<li>Through the various forms available on the Website, such as the "Contact Us", "Get in Touch with Our Team", "Get Assistance from an Advisor", etc.;</li>' +
            '<li>When you submit a spontaneous application for a position at CIQ;</li>' +
            '<li>By creating or logging into an account;</li>' +
            '<li>By subscribing to our newsletter, if applicable;</li>' +
            '<li>When you contact us by email, phone, or otherwise.</li>' +
            '</ul>' +
            '<h3>Why Is This Information Collected?</h3>' +
            '<p>We use the information collected for the following purposes:</p>' +
            '<ul>' +
            '<li>To enable you to use the Website;</li>' +
            '<li>To provide the services and/or customer support you have requested;</li>' +
            '<li>To respond to your contact requests;</li>' +
            '<li>To communicate with you about news, events, opportunities, products, or services that may interest you, or for other administrative reasons;</li>' +
            '<li>To allow you to participate in surveys, campaigns, or promotions, if applicable;</li>' +
            '<li>To collect reviews, comments, questions, requests, and complaints related to our Services and the Website;</li>' +
            '<li>To personalize your experience on the Website and improve our services;</li>' +
            '<li>Statistics, research, and analysis;</li>' +
            '<li>Website management;</li>' +
            '<li>To inform you of changes to our Website, service improvements, or service updates;</li>' +
            '<li>To inform you of changes to our Terms of Use, this Policy, or other policies;</li>' +
            '<li>To enforce this Policy or the Terms of Use of the Website;</li>' +
            '<li>To protect our rights and interests;</li>' +
            '<li>To comply with applicable laws.</li>' +
            '</ul>' +
            '<p>We only collect and use the information that is required and necessary to achieve the purposes mentioned above.</p>',
        title: 'Information You Provide Directly'
    },
    intro: {
        content: '<p>Welcome to the “conseilinnovation.quebec” website, including its functions, applications, services, or associated or successor websites, as well as any mobile versions of these sites, (collectively "Website"), operated by the Quebec Innovation Council ("CIQ" or "we").</p>' +
            '<p>The Website is an information platform about CIQ’s mission to drive innovation within Quebec businesses and society. CIQ thus supports businesses in the province to accelerate their innovation projects by directing them to the expertise and funding opportunities that meet their needs.</p>' +
            '<p>We are committed to respecting the privacy of visitors, our customers, our partners, and all other users of the Website ("Users" or "you") by protecting their personal information as defined below.</p>' +
            '<p>This privacy policy ("Policy") aims to provide information about the personal information we collect and process as part of your use of the Website and the related services offered by CIQ. You will also find the various ways to contact us to obtain more information or exercise your rights as needed. This Policy applies in conjunction with our Terms of Use and any other applicable service agreements between you and us, which are an integral part of this Policy.</p>' +
            '<p>The Website is managed from Quebec, Canada. Therefore, this Policy takes into account the requirements of the Quebec Private Sector Privacy Law, its federal equivalent, the Personal Information Protection and Electronic Documents Act, and any other law to which CIQ may be subject in the course of its activities (collectively "Laws"). The personal information you provide to CIQ will not be used for purposes other than those provided by applicable law and in compliance with this Policy.</p>' +
            '<p>By using the Website or providing your personal information, you consent to the collection, use, disclosure, and retention of your information in accordance with the terms of this Policy.</p>' +
            '<p>We recommend that you read this Policy carefully. If you do not consent, please do not disclose your personal information to us and stop using the Website. You can also unsubscribe from any newsletters we may publish or send us an email to the address provided in the "Contact Us" section below.</p>',
        title: 'Introduction'
    },
    jurisdiction: {
        content: '<p>This Policy and your use of the Website are governed by the laws of the Province of Quebec and the applicable laws of Canada, excluding conflict of law rules.</p>' +
            '<p>If there is a disagreement between you and CIQ regarding the application of this Policy or any matter related to your personal information, we first invite you to contact us as described herein. We will try to reach an amicable settlement and find an appropriate solution to the situation, without prejudice to the right of either party to resort to the courts as they see fit.</p>' +
            '<p>In the event of a dispute, you agree that it will be resolved by the competent court of the judicial district of Montreal, whose exclusive jurisdiction you acknowledge.</p>',
        title: 'Jurisdiction'
    },
    liability: {
        content: '<p>CIQ is committed to taking all reasonable means to ensure a level of confidentiality and security of personal information that meets the adequate technological standards for its industry.</p>' +
            '<p>Despite the above, you acknowledge and understand that no computer system offers absolute security and that there is always some risk when transmitting personal information over the public network, the internet.</p>' +
            '<p>Thus, you agree that CIQ cannot be held responsible for any breach of confidentiality, hacking, viruses, loss, theft, misuse, or alteration of personal information transmitted or hosted on its systems or those of a third party. You also agree to waive any claims in this regard, except in the case of gross negligence or intentional misconduct on the part of CIQ. Consequently, you agree to release CIQ and its officers, directors, affiliates, and business partners from any liability for any damages of any kind, whether direct or indirect, incidental, special, or consequential in connection with the use of your personal information.</p>' +
            '<p>In the event of a breach of the confidentiality or security of your personal information that poses a high risk to your rights and freedoms, you will be notified as soon as possible, and CIQ will take the necessary steps to preserve the confidentiality and security of your personal information.</p>',
        title: 'Limitation of Liability'
    },
    personal_information: {
        content: '<p>Personal information consists of any information that directly or indirectly identifies a natural person by reference to a single element or a combination of several, as defined by the Quebec Private Sector Privacy Law or any equivalent law in another jurisdiction. This includes, without limitation, first name, last name, phone number, mailing address, email address, etc. (collectively "Personal Information").</p>',
        title: 'What is Personal Information?'
    },
    retention: {
        content: '<p>The retention of personal information is strictly limited to the duration necessary to achieve the purposes outlined in this Policy, as well as the period required by any applicable law or standard, such as tax or accounting regulations, or for reporting purposes in the context of our mission.</p>' +
            '<p>To determine the appropriate retention period for personal information, we take into account the amount, nature, and sensitivity of the information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process it and whether we can achieve those purposes by other means, as well as applicable laws.</p>',
        title: 'How Long is Personal Information Retained?'
    },
    rights: {
        content: '<p>As a data subject, you have and can exercise the rights listed below:</p>' +
            '<ul>' +
            '<li>You have the right to be informed about the personal information we hold about you and the purposes for which we have used and use your personal information;</li>' +
            '<li>You have the right to request a paper copy of the documents containing your personal information, subject to the exceptions provided by applicable law;</li>' +
            '<li>You have the right to request rectification, modification, and updating of the personal information we hold about you if it is incomplete, ambiguous, outdated, or inaccurate;</li>' +
            '<li>You have the right to request the deletion of your personal information if the collection is not authorized by law;</li>' +
            '<li>You have the right at any time to withdraw or modify your consent to the processing of your personal information, subject to applicable legal and contractual restrictions;</li>' +
            '<li>You have the right to request that we cease disseminating your personal information and de-index any link attached to your name that gives access to that information if such dissemination violates the law or a court order;</li>' +
            '<li>You have the right to request that your personal information be communicated to you or transferred to another organization in a structured, commonly used technological format;</li>' +
            '<li>You have the right to file a complaint with the competent authority responsible for personal information protection, subject to the conditions provided by applicable law.</li>' +
            '</ul>' +
            '<p>To exercise these rights, you may contact our privacy officer in writing as indicated in the "Contact Us" section below. You may be asked to provide appropriate identification or otherwise identify yourself. In any case, we will process access or modification requests in accordance with applicable law. We will do our best to respond to you within one month. Processing times may be longer if your request is particularly complex or if you have made several requests. In that case, we will inform you accordingly.</p>',
        title: 'Your Individual Rights'
    },
    scope: {
        content: '<p>This Policy applies to Users’ personal information provided to CIQ via the Website or by any other written or oral means. Personal information is collected during your use of the Website, when you sign up for email alerts, and during your interactions with us, whether by email, phone, or otherwise, as well as during CIQ’s business relationships with its partners and clients.</p>' +
            '<p>It should be noted that this Policy does not apply to the personal information of employees, consultants, or other individuals associated with CIQ, nor to any information that is not personal information, such as those of an exclusively commercial nature.</p>',
        title: 'Scope'
    },
    security: {
        content: '<p>The Website stores the collected personal information in a secure environment, and various measures have been put in place to prevent unauthorized disclosure, use, alteration, or destruction. The Website uses SSL protocols during the transmission of your personal information, antivirus software, firewalls, and backup procedures. CIQ has also implemented other measures to ensure the security of personal information, including (i) limiting access to personal information to staff who need access to perform their duties (customer service, administration, controller, etc.), who are required to maintain confidentiality in the course of their duties; (ii) physical protection of CIQ premises; (iii) regular review of our practices and this Policy, etc.</p>',
        title: 'Security'
    },
    storage: {
        content: '<p>The personal information we collect is stored on secure, restricted-access servers, primarily in Quebec and the United States, either on our own servers or on those of third-party service providers. We take reasonable steps to ensure that any third-party service provider offers a secure and adequate environment and appropriate security measures as required by applicable laws to ensure that your personal information is permanently protected.</p>' +
            '<p>To the extent that personal information is transferred outside of Quebec, we will take the necessary steps as required by applicable laws, which may include, depending on the case: (i) obtaining your consent before proceeding with the transfer; (ii) implementing binding corporate rules; (iii) adopting standard data protection clauses approved by the competent authorities; or (iv) obtaining appropriate assurances from the recipients of the personal information in the third country that such data is adequately protected.</p>',
        title: 'Storage and Transfer'
    },
    title: 'Privacy Policy',
    who: {
        content: '<p>CIQ commits to sharing your personal information only if you request it, based on your consent, or if disclosure is otherwise required or permitted by law.</p>' +
            '<p>We may share your personal information with our partners and affiliates, advisors, advertisers, investors, or other third parties as necessary to fulfill our mission, provide our services, or manage the Website. This includes WordPress, which is responsible for hosting the Website, operating certain features, etc. CIQ also shares with Google Analytics information that may constitute personal information as described above.</p>' +
            '<p>We commit not to sell or otherwise commercialize your personal information to third parties.</p>' +
            '<p>Our service providers and partners are chosen for their expertise and reliability. They act on our behalf and according to our written instructions. We only authorize these providers to use your personal information to the extent necessary to provide services on our behalf or to comply with legal requirements. "Additionally, these service providers provide us with sufficient guarantees regarding the implementation of appropriate protection measures proportional to the sensitivity of the personal information concerned, its purpose of use, its volume, its distribution, and its medium. When our service providers no longer need your personal information, we request that they return or destroy it appropriately.</p>' +
            '<p>CIQ may disclose any personal information it holds about you to third parties to investigate, prevent, or take action regarding alleged or actual illegal activities, including but not limited to fraud and situations involving potential threats to the safety of any person. Applicable laws, both in Quebec and other jurisdictions, may also authorize a government, court of law, or state agency to require the disclosure of your personal information. We assume no responsibility in this regard and will not oppose the disclosure of your personal information if it is imposed by law.</p>',
        title: 'To Whom Do We Communicate Your Personal Information?'
    }
}