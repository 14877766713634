<template>
  <div>
    <h2 class="text-2xl md:text-4xl mb-8">{{ $t('media.add_youtube') }}</h2>
    <form action="" @submit.prevent="submit">
      <FormInput :label="$t('labels.youtube')" v-model="form.fields.youtube" name="youtube"/>
      <div class="grid grid-cols-[1fr_max-content] w-full mt-8">
        <iframe v-if="embed" :src="embed" frameborder="0" class="w-2/3 aspect-video mr-8 rounded"/>
        <button class="button ml-auto mt-auto" type="submit" :disabled="!valid || $organization.updating">{{ $t('submit') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import {FormInput} from '@/components/form/inputs'
import {FormValidMixin} from '@/components/form/mixins'

export default {
  name: 'YoutubeVideoImportModal',
  mixins: [FormValidMixin],
  components: {FormInput},
  data() {
    return {
      form: {
        fields: {
          youtube: {
            value: '',
            valid: false
          }
        }
      },
      video_id: null
    }
  },
  computed: {
    embed() {
      if (!this.video_id) return null

      return `https://www.youtube.com/embed/${this.video_id}?autoplay=0&cc_load_policy=1&controls=2&rel=0&enablejsapi=1`
    }
  },
  methods: {
    submit() {
      if (this.valid) {
        const alreadyExists = Object.values(this.$organization.data.medias).some(media => media.video_id === this.video_id)

        if(!alreadyExists) {
          this.$organization.data.medias.push({
            video_id: this.video_id,
            type: 'youtube'
          })
        }

        this.$modal.close()
      }
    }
  },
  watch: {
    'form.fields.youtube.value'() {
      if (this.form.fields.youtube.valid) {
        const url = new URL(this.form.fields.youtube.value)
        let id = url.searchParams.get('v')

        if (!id) {
          id = url.pathname.split('/').at(-1)
        }

        this.video_id = id
      } else {
        this.video_id = null
      }
    }
  }
}
</script>