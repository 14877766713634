<template>
  <button @click="$i18n.locale = toLang" class="bg-deep-indigo-base py-2.5 px-4 rounded-full md:bg-transparent uppercase text-base transition-colors hover:text-lilac-pale">
    {{ toLang }}
  </button>
</template>

<script>
export default {
  name: 'LangSwitch',
  computed: {
    toLang() {
      return this.$i18n.availableLocales.filter(locale => locale !== this.$i18n.locale)[0]
    }
  },
  watch: {
    '$i18n.locale'() {
      this.$app.init()

      if (Object.values(this.$app.organizations).length) {
        this.$app.getOrganizations()
      }

      if (this.$organization?.data?.id) {
        this.$organization.get(this.$organization.data.id)
      }
    }
  }
}
</script>