<template>
  <transition name="slide-up" appear>
    <aside v-if="isVisible && !settingsOpen"
           class="fixed bottom-0 md:bottom-5 right-0 md:right-5 bg-deep-indigo-black p-8 max-w-[475px] rounded-tl-md md:rounded-md shadow-gdpr transition-[bottom_right_border-radius] z-50">
      <p class="text-lg">
        <span>{{ $t('gdpr.banner.description') }}</span>
        <LangRouterLink :to="{name: $routes.PRIVACY}" class="ml-1 underline hover:text-lilac-pale transition-colors">
          {{ $t('gdpr.banner.more') }}
        </LangRouterLink>
      </p>
      <div class="mt-8 flex items-center gap-8">
        <button class="button" @click="acceptAll">{{ $t('gdpr.banner.accept') }}</button>
        <button class="flex items-center gap-2.5 hover:gap-3 transition-[gap]" @click="settingsOpen = true">
          <span>{{ $t('gdpr.banner.settings') }}</span>
          <Icon id="website" class="text-xs"/>
        </button>
      </div>
    </aside>
  </transition>

  <transition name="slide-right" appear>
    <div v-if="settingsOpen" class="fixed top-0 right-0 w-full h-full z-50" style="backface-visibility: hidden"
         @click="settingsOpen = false">
      <aside
          class="relative w-full h-full md:h-[calc(100dvh-2.5rem)] bg-deep-indigo-black max-w-[475px] ml-auto md:mr-5 md:mt-5 shadow-gdpr md:rounded-md"
          @click.stop>
        <div class="overflow-auto h-full pt-8 px-8 pb-44">
          <section>
            <h2 class="text-xl mb-5">{{ $t('gdpr.settings.title') }}</h2>
            <p class="description">{{ $t('gdpr.settings.description') }}</p>
            <p class="description mt-3">
              <span>{{ $t('gdpr.settings.more') }}</span>
              <LangRouterLink :to="{name: $routes.PRIVACY}"
                              class="router-link ml-1 underline hover:text-lilac-pale transition-colors">
                {{ `${$t('labels.privacy_policy')}.` }}
              </LangRouterLink>
            </p>
          </section>
          <section class="mt-6">
            <div class="flex justify-between items-center mb-5">
              <h2 class="text-xl">{{ $t('gdpr.settings.analytics.title') }}</h2>
              <div
                  :class="['w-17.5 h-8 rounded-full p-0.5 cursor-pointer transition-[background-color] relative flex items-center px-0.5 select-none', {'bg-lilac-base': gtag, 'bg-white': !gtag}]"
                  @click.stop="gtag = !gtag">
                <Transition name="fade" mode="out-in">
                <span
                    :class="['text-center uppercase text-[14px] transition-all pt-0.5 bg-transparent pl-2 pr-1.5 ml-0', {'text-lilac-base order-2 !ml-auto': !gtag}]"
                    :key="`gtag-${gtag ? 'on' : 'off'}`">{{ gtag ? 'on' : 'off' }}</span>
                </Transition>
                <span
                    :class="['w-7 aspect-square bg-white rounded-full absolute left-0.5 ml-auto top-0.5 transition-all translate-x-[38px]', {'!bg-lilac-base !-translate-x-0': !gtag}]"/>
              </div>
            </div>
            <p class="description">{{ $t('gdpr.settings.analytics.description') }}</p>
          </section>
        </div>
        <div
            class="w-full h-52 absolute bottom-0 left-0 bg-gradient-to-t from-deep-indigo-black via-deep-indigo-black via-35% pointer-events-none">
        </div>
          <button class="button absolute bottom-8 left-8" @click="saveSettings">{{ $t('gdpr.settings.save') }}</button>
      </aside>
    </div>
  </transition>
</template>

<script>
import {bootstrap, setOptions} from 'vue-gtag'
import {Icon, LangRouterLink} from '@/components/helpers'

export default {
  name: 'Gdpr',
  components: {Icon, LangRouterLink},
  data() {
    return {
      gtag: false,
      isVisible: false,
      settingsOpen: false
    }
  },
  beforeMount() {
    if (localStorage.getItem('gdpr-consent')) {
      this.isVisible = false
    } else {
      setTimeout(() => {
        this.isVisible = true
      }, 3000)
    }

    if (localStorage.getItem('gdpr-gtag')) {
      this.gtag = true
    }
  },
  mounted() {
    if (this.gtag) {
      return this.loadGtag()
    }

    this.gtag = true
  },
  methods: {
    acceptAll() {
      localStorage.setItem('gdpr-consent', '1')
      this.loadGtag()
      this.isVisible = false
    },
    disableGtag() {
      setOptions({enabled: false})
      localStorage.removeItem('gdpr-gtag')
    },
    loadGtag() {
      if (import.meta.env.VITE_APP_GTAG_ID) {
        bootstrap().then(() => {
          localStorage.setItem('gdpr-gtag', '1')
        }).catch((err) => {
          console.log('error loading gtag', err)
        })
      }
    },
    saveSettings() {
      if (this.gtag) this.loadGtag()
      else this.disableGtag()

      this.settingsOpen = false
      this.isVisible = false

      if (this.gtag) localStorage.setItem('gdpr-consent', '1')
    }
  }
}
</script>