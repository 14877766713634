import axios from 'axios'
import i18n from '@/i18n/index.js'

const aiApi = axios.create({
    baseURL: import.meta.env.VITE_APP_AI_API_URL,
    headers: {Accept: 'application/json', 'x-api-key': import.meta.env.VITE_APP_AI_API_AUTH_KEY},
    withCredentials: true
})

const baseApi = axios.create({
    baseURL: import.meta.env.VITE_APP_API_URL,
    headers: {Accept: 'application/json'},
    withCredentials: true,
    withXSRFToken: true
})
baseApi.interceptors.request.use(config => {
    config.params = config.params || {}
    config.params['lang'] = i18n.global.locale.value
    return config
})

export {
    aiApi,
    baseApi
}