<template>
  <div>
    <label v-if="!noLabel" :for="name" class="text-sm mb-5 block">{{ label }}</label>
    <div class="w-full relative">
      <input :id="name" :name="name" :placeholder="placeholder" :type="type" :value="modelValue.value" @input="input"
             :class="['text-input', {'pr-10': linkValid}]" ref="input">
      <Transition name="fade">
        <a v-if="linkValid" :href="modelValue.value" target="_blank"
           class="absolute right-0 pb-2 top-1/2 -translate-x-full -translate-y-1/2 transition-transform hover:-translate-x-[80%] hover:-translate-y-[60%]">
          <Icon id="website" class="text-xs"/>
        </a>
      </Transition>
    </div>
    <Transition name="fade">
      <p v-if="name === 'instructions' && modelValue.value?.length > 1 && !modelValue.valid" class="p-2 mt-2 rounded text-xs text-error-pale bg-error-base">{{ $t('errors.special_characters') }}</p>
    </Transition>
  </div>
</template>

<script>
import {Icon} from '@/components/helpers'

export default {
  name: 'FormInput',
  components: {Icon},
  props: {
    isLink: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    modelValue: {
      required: true
    },
    name: {
      type: String,
      required: true
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    nullable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'string',
      validator: value => ['email', 'string'].includes(value)
    }
  },
  computed: {
    linkValid() {
      return this.isLink && this.modelValue.valid && this.modelValue.value
    }
  },
  methods: {
    input(event) {
      this.$emit('update:modelValue', {valid: this.validate(event.target.value), value: event.target.value})
    },
    validate(value) {
      if (this.nullable && value === '') return true

      if (value?.length > 255) return false

      switch (this.name) {
        case 'ai_activities_start_year':
          return this.$consts.REGEX.YEAR.test(value) && value > 1970 && value <= new Date().getFullYear()
        case 'ai_activities_employee_count':
        case 'ai_activities_project_count':
          return this.$consts.REGEX.NUMBER.test(value) && value < 1000 && value > 0
        case 'contact':
        case 'email':
          return this.$consts.REGEX.EMAIL.test(value)
        case 'facebook':
          return value.includes('facebook.com') && this.$consts.REGEX.URL.test(value)
        case 'instructions':
          return this.$consts.REGEX.INSTRUCTIONS.test(value)
        case 'linkedin':
          return value.includes('linkedin.com') && this.$consts.REGEX.URL.test(value)
        case 'website':
          return this.$consts.REGEX.URL.test(value)
        case 'x':
          return (value.includes('twitter.com') || value.includes('x.com')) && this.$consts.REGEX.URL.test(value)
        case 'youtube':
          return this.$consts.REGEX.YOUTUBE.test(value)
        default:
          return this.$consts.REGEX.NAME.test(value)
      }
    }
  },
  emits: ['update:modelValue'],
  mounted() {
    this.$nextTick(() => {
      this.$emit('update:modelValue', {
        valid: this.validate(this.modelValue.value),
        value: this.modelValue.value || null
      })
    })

    if (this.name === 'instructions') {
      this.$refs.input.focus()
    }
  }
}
</script>