export default {
    add: {
        image: 'Ajouter un fichier image',
        link: 'Ajouter le lien',
        youtube: 'Ajouter un lien YouTube'
    },
    ai_employees: 'Employés dédiés à l\'IA',
    all: 'Toutes',
    cancel: 'Annuler',
    clipboard: {
        copied: 'URL copié dans le presse-papier!',
        failed: 'Oups! Un problème est survenu.'
    },
    code: 'Code d\'authentification',
    competences_definition: 'Définition des compétences',
    contact: {
        body: 'Bonjour, j\'ai visité la plateforme Miria récemment et j\'ai remarqué que ...',
        subject: 'Suggestions d\'amélioration de la plateforme Miria'
    },
    contact_us: 'Contactez-nous!',
    data: {
        analysis: 'Analyse des données',
        gathering: 'Récolte des données',
        generation: 'Génération des contenus',
        transformation: 'Traitement des données'
    },
    desktop_only: 'Version mobile à venir',
    did_you_know: 'Saviez-vous que...',
    done: 'Faites',
    email: 'Courriel',
    enter: 'Entrée',
    experience_years: 'Années d\'expérience',
    keywords: {
        hide: 'Cacher les mots-clés',
        show: 'Explorer les mots-clés'
    },
    login: {
        codes: 'Pour la phase de développement, les codes d\'authentification doivent être récupérés au <a href="CODES_URL" target="_blank">CODES_URL</a>, et non par courriel.',
        description: 'Bienvenue sur Miria!<br>S’il s’agit de votre première visite, entrez l’adresse courriel que vous souhaitez associer à votre organisation. Cette adresse deviendra votre identifiant unique.<br>Un code à 6 chiffres vous sera envoyé pour compléter l’authentification et débuter le processus de création.<br>Si vous avez déjà une fiche, entrez le courriel associé à votre organisation pour recevoir un code d’authentification.',
        title: 'Connexion'
    },
    logout: 'Se déconnecter',
    max_selection: ':current sélectionnés sur :max maximum',
    media: {
        add_image: 'Ajouter une image',
        add_legend: 'Ajouter une légende au média',
        add_youtube: 'Ajouter une vidéo Youtube'
    },
    miria: {
        ask: 'Demander à Miria'
    },
    newsletter: {
        confirmation: 'Merci de vous être abonné à notre infolettre.',
        description: 'L\'équipe du Conseil de l\'innovation du Québec travaille présentement sur plusieurs nouveaux projets. Restez informé de l\'avancement et apprenez-en plus sur les dossiers qui vous préoccupent, directement dans votre boîte de réception.',
        error: 'Votre courriel n\'a pas pu être sauvegardé. Veuillez réessayer ultérieurement.',
        submit: 'S\'inscrire à l\'infolettre',
        title: 'Restez à l\'affût de l\'évolution de la plateforme'
    },
    password: 'Mot de passe',
    photo: {
        choose: 'Choisir une Photo',
        drag_drop: 'Faites glisser et déposez ou sélectionnez une photo depuis votre ordinateur'
    },
    press_on: 'Appuyez sur',
    projects_completed: 'Projets réalisés',
    private: 'Privé',
    public: 'Public',
    save: 'Sauvegarder',
    send: 'Envoyer',
    share: 'Partager',
    shift: 'Maj',
    start: 'Commencer',
    status: 'Statut',
    submit: 'Soumettre',
    to_add_links: 'pour ajouter des liens',
    todo: 'À faire',
    trademark: '© 2024. Tous droits réservés. Miria est un projet propulsé par le Conseil de l\'innovation du Québec',
    visit_website: 'Visiter le site'
}