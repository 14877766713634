import axios from 'axios'
import {defineStore} from 'pinia'

const NEWSLETTER_ENDPOINT = 'https://app.cyberimpact.com/clients/48070/subscribe-forms/647D93F0-265A-4C9A-878D-C96CE69258B8'
const XSRF_TOKEN = '8331a87fb84aa31be77a24acb8dacde9'

export const useNewsletterStore = defineStore('newsletter', {
    state: () => {
        return {}
    },
    actions: {
        async subscribe(email) {
            try {
                await axios.post(NEWSLETTER_ENDPOINT, {
                    f_email: email,
                    f_groups: [7, 14, 20]
                }, {
                    headers: {
                        Accept: 'application/json',
                        'XSRF-TOKEN': XSRF_TOKEN
                    },
                    xsrfHeaderName: 'XSRF-TOKEN',
                    withXSRFToken: true
                })

                return true
            } catch (exception) {
                console.error(exception)

                return false
            }
        }
    }
})