export default {
    account: {
        content: '<p>Vous pouvez vous créer un compte sur le Site Internet afin de lancer une recherche de programmes de financement potentiels pour votre projet. Afin d’assurer la sécurité de votre compte, votre mot de passe devrait inclure des chiffres, lettres et caractères spéciaux. Vous êtes responsable de la protection de votre mot de passe et de toute activité faite sur votre compte, que vous ayez autorisé ou non lesdites activités. Si vous prenez conscience de ou soupçonnez raisonnablement une intrusion quelconque sur votre compte, incluant tout vol, perte ou divulgation ou utilisation non autorisée de votre mot de passe, veuillez changer immédiatement votre mot de passe et nous le signaler en communiquant avec nous de la manière prévue aux présentes.</p>',
        title: 'Création de compte'
    },
    changes: {
        content: '<p>Nous nous réservons le droit unilatéral d’apporter à tout moment des modifications à la présente Politique. Telles modifications seront affichées sur le Site Internet et entreront en vigueur immédiatement après l’affichage.</p>' +
            '<p>En continuant à utiliser le Site Internet après la publication d’une mise à jour, vous consentez à ce que nous utilisions vos Renseignements personnels conformément à la politique amendée. En cas de défaut ou de refus par un Utilisateur d’accepter la version mise à jour de la présente politique, son accès au Site Internet pourra être suspendu à partir de la date d’entrée en vigueur de la mise à jour.</p>',
        title: 'Modifications de cette Politique'
    },
    ciq: {
        content: '<p>Le CIQ a désigné un responsable de la protection de la vie privée chargé de veiller au respect de cette Politique et de la Loi applicable en matière de protection de vos Renseignements personnels. Si vous avez des questions, des préoccupations ou des plaintes concernant l’application de cette Politique ou la gestion de vos Renseignements personnels, veuillez contacter notre responsable de la protection de la vie privée dont les coordonnées sont indiquées dans la section « Contactez-nous » ci-dessous.</p>',
        title: 'Responsable de la protection des Renseignements personnels chez CIQ'
    },
    collect: {
        content: '<p>Nous recueillons les Renseignements personnels avec votre consentement ou selon ce que les Lois en vigueur permettent ou exigent. Votre consentement peut être exprès ou implicite, selon les circonstances et la Loi applicable.</p>' +
            '<p>Les services du CIQ ne sont pas destinés aux mineurs. Aussi, nous ne collectons ni ne traitons en connaissance de cause des Renseignements personnels sur ces derniers. Par votre utilisation du Site Internet, vous déclarez et garantissez que vous avez atteint l’âge légal de la majorité dans votre lieu de résidence ou que vous êtes autorisé à utiliser le Site Internet par le titulaire de l’autorité parentale à votre égard. Dans l’hypothèse où nous aurions connaissance de la collecte de Renseignements personnels de mineurs sans une telle autorisation, nous prendrions les mesures appropriées afin de supprimer ces Renseignements personnels de nos serveurs et de ceux de nos partenaires.</p>' +
            '<p>Le CIQ limitera la collecte de Renseignements personnels, autant en ce qui concerne leur nature que leur volume, à ce qui est nécessaire aux fins pour lesquelles ils sont recueillis. Nous verrons également à nous assurer que les Renseignements personnels que nous détenons sont exacts, complets et à jour.</p>',
        title: 'Collecte de Renseignements personnels'
    },
    contact: {
        content: '<p>Si vous avez des questions concernant cette Politique ou une demande écrite à notre responsable de la protection de la vie privée, veuillez nous contacter comme suit :</p>',
        title: 'Contactez-nous'
    },
    external_links: {
        content: 'En plus de ce qui est mentionné ailleurs dans la présente Politique, le Site Internet peut inclure des hyperliens vers des sites internet associés au Site web, dont lebarometre.ca ou des sites externes opérés par des tiers. Ces derniers incluent notamment les sites internet de nos partenaires de financement ainsi que des réseaux sociaux et plateformes de diffusion tels Twitter, Facebook, LinkedIn et YouTube. En cliquant sur ces hyperliens, vous quitterez le Site Internet vers ceux de ces tiers, lesquels peuvent faire l’objet de politiques et de pratiques de protection de la confidentialité différentes. La présente Politique n’est plus applicable lorsque vous quittez notre Site Internet. Veuillez consulter les politiques en matière de confidentialité de ces autres sites sur lesquels vous êtes redirigé avant de fournir des Renseignements personnels. Nous ne sommes pas responsables des pratiques en matière de collecte, d’utilisation et de communication de Renseignements personnels d’autres entités auxquelles vous pouvez accéder à partir de notre Site Internet, et nous n’acceptons aucune responsabilité à cet égard.',
        title: 'Liens externes et formulaires'
    },
    information_collected: {
        content: '<h3>Quelles sont les informations que nous recueillons ?</h3>' +
            '<p>En plus de ce qui précède, nous pouvons recueillir et collecter automatiquement des informations vous concernant lorsque vous utiliser le Site Internet, principalement les suivantes :</p>' +
            '<ul>' +
            '<li>Adresse IP;</li>' +
            '<li>Identifiants uniques des appareils;</li>' +
            '<li>Système d’exploitation;</li>' +
            '<li>Informations sur le réseau mobile;</li>' +
            '<li>Type de navigateur;</li>' +
            '<li>Applications existantes;</li>' +
            '<li>Activité de navigation;</li>' +
            '<li>Type d’appareil utilisé;</li>' +
            '<li>Pages visitées et requêtes;</li>' +
            '<li>Heure et jour de connexion;</li>' +
            '<li>Liens que vous cliquez;</li>' +
            '<li>Géolocalisation; et</li>' +
            '<li>Langue de votre navigateur.</li>' +
            '</ul>' +
            '<h3>Comment et pourquoi ces informations sont-elles recueillies ?</h3>' +
            '<p>Ces informations sont notamment recueillies par le biais de cookies (témoins). Il s’agit de petits fichiers qui sont stockés sur votre ordinateur ou sur tout appareil de communication électronique que vous utilisez lorsque vous visitez le Site Internet. Ces fichiers permettent l’échange d’informations entre le Site Internet et votre navigateur.</p>' +
            '<p>Nous analysons ces données pour nous aider à déterminer comment les Utilisateurs accèdent à certaines parties du Site Internet et qui sont nos Utilisateurs. En reconnaissant les modèles et les tendances d’utilisation, nous sommes en mesure de mieux concevoir le Site Internet afin d’améliorer l’expérience des Utilisateurs, tant en termes de contenu que de facilité d’utilisation.</p>' +
            '<p>Nous utilisons pour ce faire les cookies suivants :</p>' +
            '<ul>' +
            '<li>Cookies de session : Ces cookies sont indispensables au bon fonctionnement du Site Internet ainsi que de ses fonctionnalités. Ils ne recueillent pas d’informations destinées à être utilisées à des fins de prospection commerciale ou de ciblage publicitaire. Les cookies de session sont des identifiants encryptés non reconnaissables mais indispensables pour que vous n’ayez pas, entre autres, à vous reconnecter à chaque page que vous consultez.</li>' +
            '<li>Cookies de Google Analytics: Google Analytics collecte plusieurs types d’informations reliées à votre navigation dans la Site Internet, par exemple les pages que vous visitez, la date et l’heure de votre visite et les liens sur lesquels vous cliquez. Google Analytics collecte aussi notamment le type de système d’exploitation de votre ordinateur, la langue de votre navigateur, le nom de votre fournisseur de services Internet et votre localisation géographique. Google utilise cette information uniquement pour produire des statistiques et des rapports sur la navigation dans le Site Internet. Les données de trafic et d’utilisation collectées par Google (obtenues par le biais des cookies de Google sur le Site Internet) sont soumises à la politique de confidentialité de Google.</li>' +
            '<li>Cookies de tierces parties. Nous pourrions autoriser des cookies de tierces parties pour suivre votre parcours entre différents sites et vous fournir des publicités ciblées qui, selon nous, pourraient vous intéresser. Vous pouvez toutefois choisir en tout temps de ne pas en recevoir</li>' +
            '<li>Nous ne recueillons et n’utilisons que les informations requises et nécessaires pour nous permettre d’atteindre les objectifs mentionnés ci-dessus. La plupart des navigateurs acceptent les cookies automatiquement, mais vous pouvez habituellement refuser ceux-ci, ou les accepter de façon sélective en ajustant vos préférences dans votre navigateur. Si vous désactivez les cookies, certaines portions du Site Internet pourraient ne pas vous être disponibles. Vous devriez toutefois être capable de naviguer et effectuer des achats.</li>' +
            '</ul>' +
            '<p>À l’exception de ce qui est décrit aux présentes, le CIQ ne suit pas votre activité dans le temps et sur les différents sites internet ou services en ligne, sauf parmi les sites connexes qui constituent les Sites Internet du CIQ et comme il est décrit aux présentes. Nous ne permettons pas à nos fournisseurs de services tiers de recueillir des informations sur le Site Internet pour d’autres sites ou pour leurs propres besoins autres que ceux décrits aux présentes.</p>' +
            '<p>Si vous utilisez un ordinateur dans un lieu public ou partagez un ordinateur avec d’autres utilisateurs, nous vous recommandons d’effacer votre historique de navigation avant de mettre fin à chaque session.</p>',
        title: 'Informations que nous recueillons automatiquement'
    },
    information_provided: {
        content: '<h3>Quelles sont les informations recueillies ?</h3>' +
            '<p>Nous recueillons les Renseignements personnels suivants, que vous nous fournissez sur une base volontaire:</p>' +
            '<ul>' +
            '<li>Des coordonnées, tels votre nom et prénom, votre adresse postale, votre adresse courriel et votre numéro de téléphone;</li>' +
            '<li>Des renseignements professionnels ou relatifs à l’emploi, comme votre curriculum vitae et d’autres informations que vous nous fournissez lorsque vous postulez pour un emploi au CIQ;</li>' +
            '<li>D’autres informations qui pourraient constituer des Renseignements personnels, tels des détails sur votre projet d’innovation ou votre secteur d’activité.</li>' +
            '</ul>' +
            '<h3>Comment ces informations sont-elles recueillies ?</h3>' +
            '<p>Les Renseignements personnels que nous recueillons à votre sujet sont collectées lors des interactions avec le Site Internet ou avec nous, ceci de la manière suivante :</p>' +
            '<ul>' +
            '<li>Par le biais des différents formulaires disponibles sur le Site Internet, par exemple les onglets « Nous joindre », « Communiquer avec notre équipe », « Obtenir l’aide d’un conseiller », etc;</li>' +
            '<li>Lorsque vous soumettez votre candidature spontanée pour un poste au CIQ;</li>' +
            '<li>En vous créant un compte ou en vous y connectant;</li>' +
            '<li>En souscrivant à notre infolettre, le cas échéant;</li>' +
            '<li>Lorsque vous nous contactez par courriel, par téléphone ou autrement.</li>' +
            '</ul>' +
            '<h3>Pourquoi ces informations sont-elles recueillies ?</h3>' +
            '<p>Nous utilisons les informations recueillies aux fins suivantes :</p>' +
            '<ul>' +
            '<li>Vous permettre d’utiliser le Site Internet;</li>' +
            '<li>Fournir les services et/ou l’assistance à la clientèle que vous avez demandés;</li>' +
            '<li>Répondre à vos demandes de contact;</li>' +
            '<li>Communiquer avec vous au sujet de nouvelles, d’événements, d’opportunités, de produits ou de services qui pourraient vous intéresser ou pour d’autres raisons administratives ;</li>' +
            '<li>Vous permettre de participer à des enquêtes, des campagnes ou des promotions, le cas échéant;</li>' +
            '<li>Recueillir les avis, commentaires, questions, demandes et plaintes relativement à nos Services et au Site web;</li>' +
            '<li>Personnaliser votre expérience sur le Site Internet et améliorer nos services ;</li>' +
            '<li>Statistiques, recherche et analyse ;</li>' +
            '<li>Gestion du Site Internet ;</li>' +
            '<li>Vous informer de changements sur notre Site Internet, améliorations ou mise à jour de services;</li>' +
            '<li>Vous informer des modifications apportées à nos Conditions d’utilisation, cette Politique ou nos autres politiques;</li>' +
            '<li>Faire respecter la présente Politique ou les Conditions d’utilisation du Site Internet ;</li>' +
            '<li>Protéger nos droits et nos intérêts;</li>' +
            '<li>Respecter les Lois.</li>' +
            '</ul>' +
            '<p>Nous ne recueillons et n’utilisons que les informations qui sont requises et nécessaires pour nous permettre d’atteindre les objectifs mentionnés ci-dessus.</p>',
        title: 'Informations que vous nous fournissez directement'
    },
    intro: {
        content: '<p>Bienvenue sur le site « conseilinnovation.quebec », y compris ses fonctions, applications, services ou sites Internet associés ou successeurs de même que toute version mobile de ces sites, (collectivement « <b>Site Internet</b> » ), exploité par le Conseil de l’Innovation du Québec.(« <b>CIQ</b> » ou « <b>nous</b> »).</p>' +
            '<p>Le Site Internet est une plateforme d’information sur la mission du CIQ qui est de dynamiser l’innovation au sein des entreprises et de la société québécoise. Le CIQ accompagne ainsi les entreprises de la province afin d’accélérer leurs projets d’innovation en les aiguillant directement vers les expertises et des possibilités de financement répondant à leurs besoins.</p>' +
            '<p>Nous nous engageons à respecter la vie privée des visiteurs, de nos clients, nos partenaires ainsi que de tous les autres utilisateurs du Site Internet (« <b>Utilisateurs</b> » , ou « <b>vous</b> » ) en protégeant leurs Renseignements personnels, tel que défini ci-après.</p>' +
            '<p>La présente politique de protection de la vie privée (« <b>Politique</b> ») a pour objectif de fournir des informations sur les Renseignements personnels que nous recueillons et traitons dans le cadre de votre utilisation du Site Internet ainsi qu’aux services connexes offerts par le CIQ. Vous trouverez également les différentes façons de nous contacter afin, entre autres, d’obtenir plus d’information au besoin ou d’exercer vos droits. Cette Politique s’applique conjointement à nos Conditions d’utilisation et toute autre entente de services applicable conclue entre vous et nous, lesquelles font partie intégrante de la présente Politique.</p>' +
            '<p>Le Site Internet est administré à partir du Québec, au Canada. Cette Politique tient ainsi compte des exigences de la Loi sur la protection des renseignements personnels dans le secteur privé en vigueur au Québec, de son équivalent fédéral, la Loi sur la protection des renseignements personnels et les documents électroniques et de toute autre loi à laquelle le CIQ pourrait être assujetti dans le cadre de ses activités (collectivement « <b>Loi(s)</b> »). Les Renseignements personnels que vous allez fournir au CIQ ne seront pas utilisés à d’autres fins que celles prévues par la Loi applicable et en conformité avec la présente Politique.</p>' +
            '<p>En utilisant le Site Internet ou en y fournissant vos Renseignements Personnels, vous consentez à leur cueillette, utilisation, divulgation et conservation conformément aux conditions des présentes.</p>' +
            '<p>Nous vous recommandons de lire attentivement cette Politique. À défaut d’y consentir, veuillez ne pas nous divulguer vos Renseignements personnels et cesser d’utiliser le Site Internet. Vous pouvez également vous désabonner à tout moment de toute infolettre que nous pourrions publier ou nous envoyer un courriel à l’adresse indiquée dans la section « Contactez-nous » ci-dessous.</p>',
        title: 'Introduction'
    },
    jurisdiction: {
        content: '<p>La présente Politique et votre utilisation du Site Internet sont régies par les lois de la province de Québec et les lois du Canada qui y sont applicables, à l’exclusion des règles de conflit de lois.</p>' +
            '<p>En cas de désaccord entre vous et CIQ sur l’application de cette Politique ou de toute question reliée à vos Renseignements personnels, nous vous invitons en premier lieu à communiquer avec nous de la manière prévue aux présentes. Nous essaierons dans la mesure du possible de parvenir à un règlement à l’amiable et de trouver une solution appropriée à la situation, sans préjudice du droit de chaque partie de recourir aux tribunaux de la manière et au moment qu’elle juge appropriés.</p>' +
            '<p>Advenant qu’un litige survienne néanmoins, vous convenez que celui-ci sera résolu par le tribunal compétent du district judiciaire de Montréal, duquel vous reconnaissez la juridiction exclusive.</p>',
        title: 'Juridiction'
    },
    liability: {
        content: '<p>Le CIQ s’engage à prendre tous les moyens raisonnables afin d’assurer un niveau de confidentialité et de sécurité des Renseignements personnels conforme aux standards technologiques adéquats compte tenu de son secteur d’activité.</p>' +
            '<p>Malgré ce qui précède, vous déclarez comprendre et reconnaître qu’aucun système informatique n’offre une sécurité absolue et qu’une part de risque est toujours présente lorsque l’on transmet des Renseignements personnels sur le réseau public qu’est l’internet.</p>' +
            '<p>Vous acceptez ainsi que le CIQ ne puisse être tenu responsable de toute violation de confidentialité, piratage, virus, perte, vol, utilisation abusive ou altération des Renseignements personnels transmis ou hébergées sur ses systèmes ou ceux d’un tiers. Vous déclarez également renoncer à toute réclamation à cet égard, sauf en cas de négligence grave ou de faute intentionnelle de la part du CIQ. En conséquence, vous acceptez de dégager le CIQ et ses dirigeants, administrateurs, personnes liées et partenaires commerciaux de toute responsabilité pour tout dommage de quelque nature que ce soit, qu’il soit direct ou indirect, accessoire, spécial ou consécutif en rapport avec l’utilisation de vos Renseignements personnels.</p>' +
            '<p>Dans le cas d’une violation de la confidentialité ou de la sécurité de vos Renseignements personnels qui présente un risque élevé pour vos droits et votre liberté, cette violation vous sera notifiée dès que possible et le CIQ prendra les mesures nécessaires pour préserver la confidentialité et la sécurité de vos Renseignements personnels.</p>',
        title: 'Limitation de la responsabilité'
    },
    personal_information: {
        content: '<p>Un renseignement personnel consiste en toute information qui permet d’identifier directement ou indirectement une personne physique, par référence à un seul élément ou en combinaison de plusieurs, tel que défini par la Loi sur la protection des renseignements personnels dans le secteur privé (Québec) ou par toute autre loi équivalente d’une autre juridiction. Cela inclut, sans limitation, le prénom, nom, numéro de téléphone, adresse civique, adresse courriel, etc. (collectivement « <b>Renseignements personnels</b> »).</p>',
        title: 'Qu’est-ce qu’un Renseignement personnel?'
    },
    retention: {
        content: '<p>La conservation des Renseignements personnels est d’une durée strictement nécessaire à la réalisation des finalités visées par la présente Politique ainsi que durant la période requise par toute Loi ou norme applicable, le cas échéant, notamment la règlementation fiscale, comptable ou à des fins de rapport dans le cadre de notre mission.</p>' +
            '<p>Pour déterminer la durée de conservation appropriée des Renseignements personnels, nous prenons en compte la quantité, la nature et la sensibilité de ces derniers, le risque potentiel de préjudice résultant de l’utilisation ou de la divulgation non autorisée de vos Renseignements personnels, les finalités pour lesquelles nous les traitons et si nous pouvons atteindre ces objectifs par d’autres moyens, de même que les Lois applicables.</p>',
        title: 'Combien de temps les Renseignements personnels seront-ils conservés ?'
    },
    rights: {
        content: '<p>À titre de personne concernée, vous disposez et pouvez exercer les droits énoncés ci-dessous :</p>' +
            '<ul>' +
            '<li>Vous avez le droit d’être informé des Renseignements personnels que nous détenons à votre sujet, des informations sur les finalités pour lesquelles nous avons utilisé et utilisons vos Renseignements personnels;</li>' +
            '<li>Vous avez le droit de demander une copie papier des documents contenant vos Renseignements personnels, sous réserve des exceptions prévues par la loi applicable;</li>' +
            '<li>Vous avez le droit de faire rectifier, modifier et mettre à jour les Renseignements personnels que nous détenons à votre sujet s’ils sont incomplets, équivoques, périmés ou inexacts;</li>' +
            '<li>Vous avez le droit de demander la suppression de vos Renseignements personnels si la collecte n’est pas autorisée par la loi;</li>' +
            '<li>Vous avez le droit en tout temps de retirer ou modifier votre consentement à ce que nous traitions vos Renseignements personnels, sous réserve des restrictions légales et contractuelles applicables;</li>' +
            '<li>Vous avez le droit de nous demander de cesser de diffuser vos Renseignements personnels et de désindexer tout lien rattaché à votre nom qui donne accès à ces renseignements si cette diffusion contrevient à la loi ou à une ordonnance judiciaire;</li>' +
            '<li>Vous avez le droit de demander que vos Renseignements personnels vous soient communiqués ou qu’ils soient transférés à une autre organisation dans un format technologique structuré et couramment utilisé;</li>' +
            '<li>Vous avez le droit de porter plainte auprès de l’autorité compétente en matière de protection des Renseignements personnels, sous réserve des conditions prévues par la loi applicable.</li>' +
            '</ul>' +
            '<p>Afin de faire valoir ces droits, vous pouvez communiquer par écrit avec notre responsable de la protection de la vie privée de la manière indiquée dans la section « Contactez-nous » ci-dessous. Il peut vous être demandé de fournir un document d’identification approprié ou de vous identifier autrement Dans tous les cas, nous traiterons les demandes d’accès ou de modification conformément à la Loi applicable. Nous ferons de notre mieux pour vous répondre dans un délai d’un mois. Les délais de traitement pourront être plus long si votre demande est particulièrement complexe ou si vous avez fait un certain nombre de demandes. Dans ce cas, nous vous en tiendrons informé.</p>',
        title: 'Vos droits individuels'
    },
    scope: {
        content: '<p>Cette Politique s’applique aux Renseignements personnels des Utilisateurs transmis au CIQ via le Site Internet ou par tout autre moyen écrit ou oral. Les Renseignements personnels sont recueillis lors de votre utilisation du Site Internet, lorsque vous vous inscrivez pour recevoir des alertes par courriel et lors de vos échanges avec nous, que ce soit par courriel, par téléphone ou autrement, ainsi que dans le cadre des relations d’affaires du CIQ avec ses partenaires et clients.</p>' +
            '<p>Il est à noter que la présente Politique ne s’applique pas aux Renseignements personnels des employés, consultants ou autres individus liés au CIQ, ni à toute information qui ne constitue pas un Renseignement personnel, tel ceux de nature exclusivement commerciale.</p>',
        title: 'Champ d’application'
    },
    security: {
        content: '<p>Le Site Internet conserve les Renseignements personnels compilés dans un environnement sécurisé et diverses mesures pour empêcher leur divulgation, utilisation, altération ou destruction non autorisée ont été mises en place. Le Site Internet a notamment recours au protocole SSL lors de la transmission de vos Renseignements personnels, à des logiciels anti-virus, des pares-feux (firewall) ainsi qu’à des procédures de sauvegarde informatique. Le CIQ a également pris d’autres mesures visant à assurer la sécurité des Renseignements personnels, en plus de ceux mentionnés ailleurs dans la présente Politique, qui incluent notamment (i) l’accès limité aux Renseignement personnels par le personnel ayant besoin d’y accéder pour exécuter leurs tâches (service à la clientèle, administration, contrôleur, etc.), ceux-ci étant tenus à la confidentialité dans l’exercice de leurs fonctions; (ii) la protection physique des locaux du CIQ; (iii) l’examen régulier de nos pratiques et de la présente Politique, etc.</p>',
        title: 'Sécurité'
    },
    storage: {
        content: '<p>Les Renseignements personnels que nous recueillons sont stockés dans des serveurs sécurisés à accès restreint, basés principalement au Québec et aux États-Unis, soit sur nos propres serveurs ou sur ceux de tiers fournisseurs de services. Nous prenons les moyens raisonnables pour assurer que tout tiers fournisseur de services offre un environnement sécuritaire et adéquat et des mesures sécuritaires conformes à celles exigées par les Lois applicables afin que vos Renseignements personnels soient protégés en permanence.</p>' +
            '<p>Dans la mesure où des Renseignements personnels sont transférés à l’extérieur du Québec, nous prendrons les mesures requises par les Lois applicables, qui pourraient notamment inclure selon le cas: (i) l’obtention de votre consentement avant de procéder au transfert; (ii) la mise en place de règles d’entreprises contraignantes; (iii) l’adoption de clauses types de protection des données approuvées par les autorités compétentes; ou (iv) l’obtention de garanties appropriées de la part des destinataires des Renseignements personnels dans le pays tiers à l’effet que ces derniers sont adéquatement protégés.</p>',
        title: 'Stockage et transfert'
    },
    title: 'Politique de protection de la vie privée',
    who: {
        content: '<p>Le CIQ s’engage à ne partager vos Renseignements personnels que si vous en faites la demande, sur la base de votre consentement ou si la divulgation est autrement exigée ou autorisée par la Loi.</p>' +
            '<p>Nous pourrions ainsi partager vos Renseignements personnels avec nos partenaires et affiliés, conseillers, annonceurs, investisseurs ou autres tierce parties de la manière nécessaire à l’accomplissement de notre mission, la prestation de nos services ou la gestion du Site Internet. Cela inclut notamment WordPress, dont le mandat est d’héberger le Site Internet, en opérer certaines fonctionnalités, etc. Le CIQ partage également avec Google Analytics des informations pouvant constituer des Renseignements personnels de la manière décrite ci-haut.</p>' +
            '<p>Nous nous engageons à ne pas vendre ou autrement commercialiser vos Renseignements personnels à des tiers.</p>' +
            '<p>Nos prestataires de services et partenaires sont choisis pour leur expertise et leur fiabilité. Ils agissent en notre nom et selon nos instructions écrites. Nous n’autorisons ces prestataires à utiliser vos Renseignements personnels que dans la mesure où cela s’avère nécessaire pour rendre des services en notre nom ou pour respecter des exigences légales. « De plus, ces prestataires de services nous offrent des garanties suffisantes quant à la mise en œuvre de mesures de protection adéquates qui sont proportionnelles à la sensibilité des Renseignements personnels concernés, à la finalité de leur utilisation, à leur quantité, à leur répartition et à leur support. Lorsque nos Fournisseurs de services n’ont plus besoin de vos Renseignements personnels, nous leur demandons de nous retourner ou de détruire ces données de manière appropriée.</p>' +
            '<p>Le CIQ peut divulguer toute information personnelle détenue sur vous à des tiers afin d’enquêter, de prévenir ou de prendre des mesures concernant des activités illégales présumées ou réelles, y compris, mais sans s’y limiter, la fraude et les situations impliquant des menaces potentielles à la sécurité de toute personne. Les Lois applicables, autant au Québec que dans d’autres juridictions, peuvent également faire en sorte qu’un gouvernement, une cour de justice ou un organisme étatique soient autorisés à exiger la divulgation de vos Renseignements personnels. Nous n’assumons aucune responsabilité à cet égard et ne nous opposeront pas à la divulgation de vos Renseignements personnels advenant le cas où elle serait imposée par une Loi.</p>',
        title: 'À qui communiquons-nous vos Renseignements personnels ?'
    }
}