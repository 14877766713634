<template>
  <div class="h-full overflow-auto">
    <h2 class="text-2xl md:text-4xl pr-10 leading-9 mb-8">{{ $t('competences_definition') }}</h2>
    <div class="flex flex-col gap-8">
      <div v-for="(competence, competenceIndex) in competences" :key="`competences-${competenceIndex}`">
        <h3 class="mb-3">{{ competence.name }}</h3>
        <div class="description text-base flex flex-col gap-4">
          <div v-for="(definition, definitionIndex) in competence.definition" :key="`competences-${competenceIndex}-definitions-${definitionIndex}`">
            <p class="mb-2">{{ definition.title }}</p>
            <p>{{ definition.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompetencesModal',
  computed: {
    competences() {
      return this.$modal.data
          .map(id => this.$app.data.competences[id] ?? null)
          .filter(competence => competence)
    }
  }
}
</script>