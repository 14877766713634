<template>
  <div>
    <h2 class="text-2xl md:text-4xl">{{ $t('labels.add_comments') }}</h2>
    <form @submit.prevent="submit">
      <FormTags name="comment_tags" v-model="form.fields.tags" class="mt-8 mb-10"/>
      <FormInput name="message" v-model="form.fields.message" :label="$t('labels.feedback_message')"
                 :placeholder="$t('labels.feedback_message')" no-label nullable/>
      <button type="submit" class="button ml-auto mt-8" :disabled="!valid">{{ $t('submit') }}</button>
    </form>
  </div>
</template>

<script>
import {FormInput, FormTags} from '@/components/form/inputs'

export default {
  name: 'OrganizationSearchFeedbackModal',
  components: {FormInput, FormTags},
  data() {
    return {
      form: {
        fields: {
          message: {
            valid: false,
            value: null
          },
          tags: {
            valid: false,
            value: []
          }
        }
      }
    }
  },
  computed: {
    valid() {
      if(this.form.fields.message.valid && this.form.fields.message.value?.length) {
        return true
      }

      const tagsLength = this.form.fields.tags.value.length
      return tagsLength > 1 || (tagsLength && !this.form.fields.tags.value.includes('other'))
    }
  },
  methods: {
    submit() {
      this.$organization.saveSearchFeedback({message: this.form.fields.message.value, tags: this.form.fields.tags.value})

      this.$modal.close()
    }
  }
}
</script>