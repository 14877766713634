export const setMeta = seo => {
    const metas = document.getElementsByTagName('meta')

    if (seo.title) {
        document.title = seo.title
        metas['og:title'].content = seo.title
    }

    if (seo.description) {
        if(seo.description.length > 160) {
            seo.description = seo.description.substring(0, 157) + '...'
        }

        metas['description'].content = seo.description
        metas['og:description'].content = seo.description
    }
}

export const setOgImage = url => {
    const metas = document.getElementsByTagName('meta')

    metas['og:image'].content = url
}
