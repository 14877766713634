import {createI18n} from 'vue-i18n'

import enDefault from '@/i18n/en/default.js'
import enErrors from '@/i18n/en/errors.js'
import enGDPR from '@/i18n/en/gdpr.js'
import enLabels from '@/i18n/en/labels.js'
import enOnboarding from '@/i18n/en/onboarding.js'
import enOrganization from '@/i18n/en/organization.js'
import enPrivacy from '@/i18n/en/privacy.js'
import enSeo from '@/i18n/en/seo.js'

import frDefault from '@/i18n/fr/default.js'
import frErrors from '@/i18n/fr/errors.js'
import frGDPR from '@/i18n/fr/gdpr.js'
import frLabels from '@/i18n/fr/labels.js'
import frOnboarding from '@/i18n/fr/onboarding.js'
import frOrganization from '@/i18n/fr/organization.js'
import frPrivacy from '@/i18n/fr/privacy.js'
import frSeo from '@/i18n/fr/seo.js'

export default createI18n({
    legacy: false,
    locale: 'fr',
    fallbackLocale: 'fr',
    messages: {
        en: {
            ...enDefault,
            errors: enErrors,
            gdpr: enGDPR,
            labels: enLabels,
            onboarding: enOnboarding,
            organization: enOrganization,
            privacy: enPrivacy,
            seo: enSeo
        },
        fr: {
            ...frDefault,
            errors: frErrors,
            gdpr: frGDPR,
            labels: frLabels,
            onboarding: frOnboarding,
            organization: frOrganization,
            privacy: frPrivacy,
            seo: frSeo
        }
    },
    warnHtmlMessage: false
})
