export default {
    add: {
        image: 'Add an image file',
        link: 'Add the link',
        youtube: 'Add a YouTube link'
    },
    ai_employees: 'Employees dedicated to AI',
    all: 'All',
    cancel: 'Cancel',
    clipboard: {
        copied: 'URL copied to clipboard!',
        failed: 'Oops! Something went wrong.'
    },
    code: 'Authentication code',
    competences_definition: 'Definition of competences',
    contact: {
        body: 'Hello, I recently visited the Miria platform and I noticed that ...',
        subject: 'Suggestions to improve the Miria platform'
    },
    contact_us: 'Contact us!',
    data: {
        analysis: 'Data analysis',
        gathering: 'Data gathering',
        generation: 'Content generation',
        transformation: 'Data transformation'
    },
    desktop_only: 'Desktop only',
    did_you_know: 'Did you know...',
    done: 'Done',
    email: 'Email',
    enter: 'Enter',
    experience_years: 'Years of experience',
    keywords: {
        hide: 'Hide the keywords',
        show: 'Show the keywords'
    },
    login: {
        codes: 'For the development phase, the authentication codes must be retrieved at <a href="CODES_URL" target="_blank">CODES_URL</a>, not by email.',
        description: 'Welcome to Miria!<br>If this is your first visit, enter the email address you wish to associate with your organization. This address will become your unique identifier.<br>A 6-digit code will be sent to you to complete authentication and begin the creation process.<br>If you already have a profile, enter the email associated with your organization to receive an authentication code.',
        title: 'Login'
    },
    logout: 'Logout',
    max_selection: ':current of maximum :max selected',
    media: {
        add_image: 'Add an image',
        add_legend: 'Add a legend to the media',
        add_youtube: 'Add a Youtube Video'
    },
    miria: {
        ask: 'Ask Miria'
    },
    newsletter: {
        confirmation: 'Thank you for subscribing to our newsletter.',
        description: 'The Québec Innovation Council team is currently working on several new projects. Stay informed about the progress and learn more about the topics that concern you, directly in your inbox.',
        error: 'Your email could not be saved. Please try again later.',
        submit: 'Subscribe to the newsletter',
        title: 'Stay updated on the platform’s evolution'
    },
    password: 'Password',
    photo: {
        choose: 'Choose Photo',
        drag_drop: 'Drag & drop or select a photo from your computer'
    },
    press_on: 'Press on',
    projects_completed: 'Projects completed',
    private: 'Private',
    public: 'Public',
    save: 'Save',
    send: 'Send',
    share: 'Share',
    shift: 'Shift',
    start: 'Start',
    status: 'Status',
    submit: 'Submit',
    to_add_links: 'to add links',
    todo: 'Todo',
    trademark: '© 2024. All rights reserved. Miria is a project powered by the Québec Innovation Council.',
    visit_website: 'View the website'
}