<template>
  <Transition name="fade" mode="out-in">
    <div v-if="ready" class="w-full h-full">
      <Transition name="fade" mode="out-in">
        <div class="w-full h-full pt-30 md:pt-0">
          <Navigation/>
          <RouterView v-slot="{Component}">
            <Transition name="fade" mode="out-in">
              <component :is="Component" :key="$route.name"/>
            </Transition>
          </RouterView>
          <Modal/>
          <ServerNotice/>
          <Gdpr/>
        </div>
      </Transition>
    </div>
    <Loader v-else/>
  </Transition>
  <Background/>
</template>

<script>
import {Gdpr} from '@/components/blocks'
import {Modal} from '@/components/modal'
import {Navigation} from '@/components/navigation'
import {Background, Loader, ServerNotice} from '@/components/small'
import {useLangRouter} from '@/extensions/langRouter'
import {setOgImage} from '@/helpers/index.js'

export default {
  name: 'App',
  setup() {
    useLangRouter()
  },
  components: {Background, Gdpr, Loader, Modal, Navigation, ServerNotice},
  data() {
    return {
      ready: false
    }
  },
  async mounted() {
    await this.$app.init()

    this.ready = true
  },
  watch: {
    '$i18n.locale': {
      handler(to) {
        setOgImage(`${window.location.origin}/og-image-${to}.jpg`)
      },
      immediate: true
    }
  }
}
</script>