<template>
  <component :is="component" :to="to" class="border-b border-transparent sm:border-b-white sm:pb-1 mr-2 group transition-[padding_margin_border] hover:pr-2 disabled:hover:pr-0 hover:mr-0 disabled:hover:mr-2 flex items-start disabled:cursor-not-allowed">
    <span :class="['text-base md:text-lg w-max sm:max-w-full leading-5', {'max-w-28': !full}]">{{ label }}</span>
    <Icon :id="icon" :class="['text-xs mt-1 md:mt-2 transition-[transform] group-hover:translate-x-2 group-disabled:!translate-x-0 shrink-0', {'ml-2.5': full, 'ml-1 sm:ml-2.5': !full}]"/>
  </component>
</template>

<script>
import {Icon, LangRouterLink} from '@/components/helpers'

export default {
  name: 'UnderlineButton',
  components: {Icon},
  props: {
    full: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    to: {
      type: Object,
      default: null
    }
  },
  computed: {
    component() {
      if(this.to) return LangRouterLink
      return 'button'
    }
  }
}
</script>