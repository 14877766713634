export default {
    login: {
        description: 'Drive your AI project to success with Miria experts.',
        title: 'Login - Miria'
    },
    organization: {
        index: {
            description: 'Drive your AI project to success with Miria experts.',
            title: 'Miria'
        },
        edit: {
            description: 'Drive your AI project to success with Miria experts.',
            title: 'My Organization - Miria'
        },
        show: {
            description: 'Drive your AI project to success with Miria experts.',
            title: '[ORGANIZATION_NAME] - Miria'
        },
    },
    privacy: {
        description: 'Drive your AI project to success with Miria experts.',
        title: 'Privacy Policy - Miria'
    },
    vitrine: {
        description: 'Drive your AI project to success with Miria experts.',
        title: 'Vitrine AI Migration Dashboard - Miria'
    }
}