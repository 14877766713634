export default {
    competences: 'Compétences',
    contact: 'Contacter',
    create: 'Inscrire mon organisation',
    edit: 'Modifier mon organisation',
    follow: 'Suivre',
    help: {
        how_to: 'Comment parler à Miria?',
        ideas: 'Idées pour débuter votre recherche'
    },
    index: {
        placeholder: 'De quoi as-tu besoin?',
        title: 'Propulsez <em>votre projet d\'IA</em> vers le succès avec les experts Miria'
    },
    name: 'Nom de l\'organisation',
    partnership_benefits: 'Que retirez-vous d\'un partenariat avec cette organisation?',
    search: 'Rechercher une organisation',
    sections: {
        details: 'Détails',
        intro: 'Intro',
        links: 'Liens',
        offer: 'Offre',
        visit: 'Visiter'
    },
    section_slugs: {
        details: 'details',
        intro: 'intro',
        links: 'liens',
        offer: 'offre'
    },
    target_audiences: 'Publics cibles',
    type: 'Type',
    view_more: 'Voir plus d\'organisations',
    visit: 'Visiter votre fiche'
}