import {defineStore} from 'pinia'
import {useOrganizationStore} from '@/stores/organization.js'

const SLIDES = [
    {slide: 'intro', section: null},
    {slide: 'url', section: 'analysis'},
    {slide: 'analysis', section: 'analysis'},
    {slide: 'scraping-preview', section: 'analysis'},
    {slide: 'contact', section: 'contact'},
    {slide: 'socials', section: 'contact'},
    {slide: 'logo', section: 'contact'},
    {slide: 'audience', section: 'details'},
    {slide: 'competences', section: 'details'},
    {slide: 'offer_description', section: 'offer'},
    {slide: 'knowledge', section: 'offer'},
    {slide: 'partnership_benefits', section: 'offer'},
    {slide: 'expertises', section: 'adds'},
    {slide: 'ai', section: 'adds'},
    {slide: 'medias', section: 'adds'},
    {slide: 'links', section: 'adds'},
    {slide: 'preview', section: 'confirm'},
    {slide: 'outro', section: null}
]

export const useOnboardingStore = defineStore('onboarding', {
    state: () => {
        return {
            highest_visited_step: 0,
            section: null,
            sections: [],
            slide: 'intro',
            step: 0,
            visited_sections: []
        }
    },
    actions: {
        back() {
            if (this.step > 0) {
                this.step--
            }
            this.updateSlide()
        },
        forward() {
            if (this.step < SLIDES.length - 1) {
                this.step++
            }
            this.updateSlide()
        },
        handleOutro() {
            if(this.step === SLIDES.length - 1) {
                this.back()
            }
        },
        init() {
            this.sections = SLIDES.reduce((carry, current, index) => {
                if (current.section && !carry.some(({name}) => name === current.section)) {
                    carry.push({name: current.section, start: index})
                }
                return carry
            }, [])

            const organization = useOrganizationStore().data
            if (organization) {
                if (organization.published) {
                    this.visited_sections = ['analysis', 'contact', 'details', 'offer', 'adds', 'confirm']
                    this.step = SLIDES.length - 2
                } else if (organization.name) {
                    this.visited_sections = ['analysis']
                    this.step = 3
                }
            }

            this.updateSlide()
        },
        navigate(step) {
            this.step = step
            this.updateSlide()
        },
        navigateToSlide(slideName) {
            for (let i = 0; i < SLIDES.length; i++) {
                if (SLIDES[i].slide === slideName) {
                    if (i <= this.highest_visited_step) {
                        this.navigate(i)
                    }
                    break
                }
            }
        },
        updateSlide() {
            this.section = SLIDES[this.step].section
            this.slide = SLIDES[this.step].slide

            if (this.step > this.highest_visited_step) {
                this.highest_visited_step = this.step
            }

            if (!this.visited_sections.includes(this.section)) {
                this.visited_sections.push(this.section)
            }
        },
        reset() {
            this.highest_visited_step = 0
            this.section = null
            this.sections = []
            this.slide = 'intro'
            this.step = 0
            this.visited_sections = []
        }
    }
})