<template>
  <div class="flex flex-wrap gap-2.5">
    <div v-for="option in options" :key="`option-${option.key}`">
      <input type="checkbox" :name="name" :value="option.key" :id="`option-${option.key}`" class="hidden"
             @change="updateModelValue">
      <label :for="`option-${option.key}`"
             :class="['text-sm bg-deep-indigo-vitrine border border-lilac-medium py-1.5 px-3 block rounded-lg cursor-pointer transition-[background-color_color] select-none',
             {'!bg-lilac-pale text-deep-indigo-black': modelValue.value.includes(option.key)}]">
        {{ option.value }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormTags',
  props: {
    modelValue: {
      required: true,
      type: Object
    },
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    options() {
      return Object.entries(this.$app.data[this.name])
          .map(([key, data]) => {
            return {
              key: key,
              value: data
            }
          })
    }
  },
  methods: {
    updateModelValue(event) {
      const value = this.modelValue.value.includes(event.target.value)
          ? this.modelValue.value.toSpliced(this.modelValue.value.indexOf(event.target.value), 1)
          : Array.from(this.modelValue.value).concat([event.target.value])

      this.$emit('update:modelValue', {valid: value, value: value})
    }
  },
  emits: ['update:modelValue']
}
</script>